<template>
<v-card>
    <v-card-text>
        <v-row>
            <v-col cols="12">
                <h3>My Cart: <span class="texr-right">{{cart.length}} items</span></h3>
                <p v-if="cart.length == 0">
                    Cart is Empty... 
                </p>
            </v-col>
            <v-col cols="12">
                <v-simple-table class="table mb-4">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Qty</th>
                            <th>Unit Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in cart" :key="item.product_id">
                            <td>
                                <img class="d-none d-sm-inline" height="100px" :src="item.images"/>
                                <v-btn icon @click="removeItem(item)">
                                    <v-icon color="red">mdi-close</v-icon>
                                </v-btn>
                            </td>
                            <td class="font-weight-bold text-left">{{item.name}}</td>
                            <td class="text-left">
                                <input @change="checkMaxAllowedQty(item)" v-model="item.qty" type="number" class="qty"/>
                            </td>
                            <td class="text-left">{{item.currency}}{{item.discount_price.toLocaleString()}}</td>
                        </tr>
                        <tr>
                            <td colspan="4" class="font-weight-bold title">Total: {{currency}}{{totalPrice.toLocaleString()}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>                
            </v-col>
            <v-app-bar>                
                <router-link class="text-decoration-none" to="/products"> &laquo; Continue Shopping</router-link>
                <v-spacer></v-spacer>
                <router-link v-if="cart.length > 0" class="text-decoration-none" to="/checkout"> Checkout &raquo;
                </router-link>
            </v-app-bar>
        </v-row>
    </v-card-text>
</v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: 'CartTable',
    computed : {
        ...mapState({
            cart : state => state.cart,
            products : state => state.products,
        }),
        currency(){
            return this.cart.length > 0 ? this.cart[0].currency : null
        },
        totalPrice(){
            return this.cart.reduce((total, next) => {
                return total + (next.discount_price * next.qty)
            }, 0)
        }
    },
    methods:{
        removeItem(item){
            this.$store.commit("removeCartItem", item)
        },
        checkMaxAllowedQty(item){
            this.products.filter((prod) => {
                if (prod.product_id == item.product_id && item.qty > prod.qty) {
                    return item.qty = prod.qty;
                }
            });
        }
    }
}
</script>

<style scoped>
.table{
    border: 1px solid #000 !important
}
.qty{
    border: 2px solid #000;
    width: 60px !important;
}
</style>