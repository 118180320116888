<template>
<v-container>
    <v-row>
        <v-col cols="12">
            <cart-table/>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import CartTable from '../components/ShopParts/CartTable.vue'
  export default {
  components: { CartTable },
    name: 'Cart',
  }
</script>
